<template>
    <!-- 场地订单 -->
    <div>
        <!-- 搜索栏 & 标签页 -->
        <order-search
            :start="startDate"
            :end="endDate"
            @handle-start="handleStart"
            @handle-end="handleEnd"
            @handle-search="getData">
        </order-search>
        <order-tabs
            v-model="tab"
            :tabs-list="tabList"
            @tab-click="getData">
        </order-tabs>
        <!-- 表格 -->
        <center-order-table
            table-title="场地订单"
            :table-list="orderTableList">
            <!-- 价格 -->
            <template #price="{price}">
                <div>{{"¥ "+ price}}</div>
            </template>
            <!-- 实付款 -->
            <template #payment="{price}">
                <div>{{"¥ "+ price}}</div>
            </template>
            <!-- 交易状态 -->
            <template #status="{status, id}">
                <div>{{status | ostatus}}</div>
                <router-link
                    :to="{
                        name: 'fieldPay',
                        query:{id}
                    }">
                    订单详情
                </router-link>
            </template>
            <!-- 操作 -->
            <template #handle="{row}">
                <!-- 我的订单 -->
                <div v-if="row.userID == user_id">
                    <!-- 去支付 -->
                    <router-link
                        :to="{
                            name: 'fieldPay',
                            query:{id: row.orderID}
                        }"
                        class="center-site-handle"
                        v-if="row.orderStatus==0">
                        去支付
                    </router-link>
                    <!-- 取消 -->
                    <a href="javascript:;"
                        class="center-site-handle"
                        v-if="row.orderStatus!=-1&&row.orderStatus!=-2 || row.orderStatus==9&&row.orderStatus!=-2"
                        @click="handleStatus(row.orderID, -1)">

                    </a>
                </div>
            </template>
        </center-order-table>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import order from '@/mixins/centerOrder'

export default {
    filters:{
        // 订单状态过滤器
        ostatus(val){
            switch(val){
                case 0:
                    return "待付款";
                    break;
                case 1:
                    return "待接单";
                    break;
                case 2:
                    return "已同意";
                    break;
                case 3:
                    return "已拒绝";
                    break;
                case 4:
                    return "租赁中";
                    break;
                case 9:
                    return "已完成";
                    break;
                case -1:
                    return "取消";
                    break;
				case -2:
				    return "已过期";
				    break;
            }
        },
    },
    mixins: [ order ],
    data() {
        return {
			tab:"-3",
            tabList: [
                {
                    value: "-3",
                    label: "全部",
                },
                {
                    value: "1",
                    label: "待确认",
                },
                {
                    value: "2",
                    label: "已同意",
                },
                //{
               //     value: "4",
                //    label: "待使用",
                //},
                {
                    value: "9",
                    label: "已完成",
                },
                {
                    value: "-1",
                    label: "已取消",
                },
				{
				value: "-2",
				label: "过期订单",
				}
            ]
        }
    },
    computed: mapState({
        user_id: state => state.user.user_id,
        orderTableList: state => state.order.orderTableList
    }),
    methods: {
        ...mapMutations([
            'handleLoading',
            'setCenterOrder'
        ]),
        ...mapActions([
            'getCenterOrder',
            'handleOrderStatus'
        ]),
        getParams() {
            let userID = this.user_id,
                fromTime = this.startDate,
                toTime = this.endDate,
                orderStatus = this.tab == '-3'? '': this.tab;
            return this.$qs.stringify({
                userID,
                fromTime,
                toTime,
                orderStatus,
                type: 1
            })
        },
        getData() {
            let url = 'usercenter/fieldorder',
                params = this.getParams();
            this.getCenterOrder({
                url,
                params
            })
        },
        handleStatus(orderID, status){ // 改变订单状态
            this.$confirm("是否取消该订单？", "提示" ,{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let url = '/usercenter/fieldOrderStatus',
                    params = this.$qs.stringify({
                        userID: this.user_id,
                        orderID,
                        status
                    });

                this.handleLoading(true);

                return this.handleOrderStatus({
                    url,
                    params
                })
            }).then(res => {
                let code = res.data.code == 200;

                this.$message({
                    showClose: true,
                    message: res.data.message,
                    type: code? 'success': 'error'
                })
                code && this.getData();
            }).catch(() => {
            }).finally(() => {
                this.handleLoading(false);
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.getData();
        })
    },
    beforeRouteLeave (to, from, next) {
        this.setCenterOrder([]);
        next();
    }
}
</script>

<style scoped>
.center-site-handle{
    display: block;
}
.center-site-handle+.center-site-handle{
    margin-top: 10px;
}
</style>
